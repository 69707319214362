<template>
  <div v-loading="loading" class="lecture">
    <div class="lecture-detail-inner">
      <div class="lecture-detail-inner-inner">
        <div class="lecture-header">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/web/lecture' }"
              >名门讲堂</el-breadcrumb-item
            >
            <el-breadcrumb-item>课程详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="lecture-detail">
          <div class="card">
            <img class="thumb" :src="detail.image" />
            <div class="right-content">
              <div class="title">
                {{ detail.title || "三分钟教你认识名门修谱" }}
              </div>
              <div class="info">
                课程类型：<span>{{ detail.cat_name }}</span>
              </div>
              <div class="info">
                发布时间：<span>{{
                  $format(detail.cdatetime, "YYYY-MM-DD")
                }}</span>
              </div>
              <div class="info">
                浏览次数：<span>{{ detail.view_count }}</span>
              </div>
              <div class="info">
                价格：<span class="price">{{
                  detail.price == 0 ? "免费" : detail.price
                }}</span>
              </div>
              <div class="play-btn" @click="handlePlay">
                立即观看
              </div>
            </div>
          </div>

          <!-- <div class="content" v-html="detail.content"></div>
          <div class="bottom">
            <div class="date">{{ detail.cdatetime || "2020-08-09" }}</div>
            <div class="view-count">
              浏览 {{ detail.view_count || "100" }} 次
            </div>
          </div> -->
        </div>
        <div class="tabs">
          <div class="underline" :style="`left: ${activeTab.left}`"></div>
          <span
            v-for="item in tabs"
            class="tab-item"
            :key="item.route"
            :class="{ active: isActiveTab(item) }"
            @click="activeTab = item"
          >
            {{ item.label }}
          </span>
        </div>
        <div class="play-list" v-show="activeTab.route === 'play-list'">
          <div
            class="play-list-item"
            v-for="item in playList"
            :key="item.id"
            @click="handleToLecture(item.id)"
          >
            <img :src="item.image" alt="" class="thumb" />
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="duration">
                {{ $format(item.cdatetime, "YYYY-MM-DD") }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="intro"
          v-show="activeTab.route === 'intro'"
          v-html="detail.description"
        ></div>
      </div>
    </div>
    <pay-dialog
      :type="3"
      :bookId="detail.id"
      :bookPrice="detail.price"
      ref="pay-dialog"
      @success="fetchDetail"
    />
    <tips ref="tips" title="提示">
      <img class="icon" src="@/assets/warn.png" alt="" />
      <div class="tips-title">是否消耗次数观看？</div>
      <div class="tips-desc">
        如继续观看，则将消耗课程观看名额
        <span style="font-size: 12px"
          >(<span class="remain">{{ userInfo.last_lec_count }}</span
          >/{{ userInfo.last_lec_count + userInfo.lec_view_count }})</span
        >
      </div>
      <button class="confirm-next-btn" @click="confirmNext">继续</button>
    </tips>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem, Button } from "element-ui";
import { mapState } from "vuex";
import otherApis from "@/apis/other";
import PayDialog from "@/components/web/PayDialog";
import Tips from "@/components/web/Tips";

const TABS = [
  {
    label: "课程目录",
    route: "play-list",
    left: "58px"
  },
  {
    label: "简介",
    route: "intro",
    left: "144px"
  }
];

export default {
  name: "LectureDetail",
  components: {
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem,
    PayDialog,
    Tips
  },
  data() {
    return {
      tabs: TABS,
      activeTab: TABS[0],
      detail: {},
      playList: [],
      loading: false
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab.route === tab.route;
    },

    async fetchDetail() {
      this.loading = true;
      try {
        const {
          data: { course_info, isbuy, isduihuan }
        } = await otherApis.courseDetail({
          id: this.$route.params.id
        });

        this.detail =
          {
            ...course_info,
            isbuy,
            isduihuan
          } || {};
        this.fetchPlayList(1);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async fetchPlayList(page) {
      this.page = page;
      try {
        const {
          data: { course_list, max_page }
        } = await otherApis.courseIndex({ cat_id: this.detail.cat_id });
        this.maxPage = max_page;
        this.playList = course_list;
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
    },

    async fetchSourceUrl() {
      try {
        const {
          data: { source_path }
        } = await otherApis.courseDetailUrl({
          id: this.detail.id
        });
        console.log(source_path);

        return source_path;
      } catch (error) {
        console.log(error);
      }
    },

    async handlePlay() {
      if (
        parseFloat(this.detail.price) === 0 ||
        this.detail.isbuy ||
        this.detail.isduihuan
      ) {
        const url = await this.fetchSourceUrl();
        window.open(url);
      } else {
        if (!this.userInfo.isLogin) {
          // 未登陆
          console.log("为登陆");
          this.bus.emit("login");
        } else {
          // 已登陆 显示购买或者消耗次数弹窗
          console.log("fetch");
          // const url = await this.fetchSourceUrl();
          // window.open(url);
          // this.fetchPdfs(detail.view_page);
          this.showPayDialog();
        }
      }
      // if (this.detail.type === 1) {
      //   window.open(this.detail.source_url);
      // } else {

      // }
    },

    async confirmNext() {
      this.$refs["tips"].hide();
      const url = await this.fetchSourceUrl();
      window.open(url);
    },

    showPayDialog() {
      if (this.userInfo.last_lec_count > 0) {
        // 是vip 使用vip次数
        this.$refs["tips"].show();
      } else {
        // 购买
        this.$refs["pay-dialog"].show();
      }
    },

    handleToLecture(id) {
      if (id === this.detail.id) return;
      this.$router.push(`/web/lecture/${id}`);
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.params.id !== oldVal.params.id) {
        this.fetchDetail();
      }
    }
  }
};
</script>

<style lang="scss" scope>
.lecture {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  // height: calc(100vh - 70px);

  .tabs {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;
    margin-bottom: 10px;

    .underline {
      width: 18px;
      height: 2px;
      background: #CA0A15;
      position: absolute;
      bottom: 1px;
      transition: left 0.3s ease;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 20px;
      content: "";
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #f0f0f0;
    }

    .tab-item {
      padding: 0 20px;
      font-size: 14px;
      color: #666666;
      line-height: 50px;
      cursor: pointer;

      &.active {
        color: #CA0A15;
      }
    }
  }

  .lecture-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .lecture-detail-inner {
    width: 1200px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
  }

  .lecture-detail-inner-inner {
    // background: #f7f7f7;
    margin: 20px;
    // padding: 20px;
  }
}

.tips-title {
  margin-top: 19px;
  font-size: 16px;
  color: #CA0A15;
}

.tips-desc {
  margin-top: 14px;
  font-size: 14px;
  color: #666666;

  .remain {
    color: #ff5500;
  }
}

.confirm-next-btn {
  margin-top: 30px;
  height: 40px;
  width: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #CA0A15;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.lecture-detail {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;

  .card {
    height: 200px;
    display: flex;

    .info {
      font-size: 16px;
      color: #333333;

      span {
        color: #666666;
      }
    }

    .price {
      color: #ca0a15;
    }

    .thumb {
      height: 200px;
      width: 200px;
      background-color: #ddd;
      object-fit: cover;
    }

    .right-content {
      height: 200px;
      margin-left: 20px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      .play-btn {
        width: 180px;
        height: 40px;
        background: #ca0a15;
        border-radius: 4px;
        font-size: 16px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 24px;
    color: #333333;
  }

  .content {
    font-size: 14px;
    color: #666666;
    margin-top: 19px;
    line-height: 1.4;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    margin-top: 18px;
  }
}

.play-list {
  .play-list-item {
    width: 1160px;
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .thumb {
      height: 80px;
      width: 80px;
      object-fit: cover;
    }

    .info {
      margin-left: 20px;

      .title {
        font-size: 18px;
        color: #333333;
      }

      .duration {
        font-size: 14px;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
}

.intro {
  white-space: normal;
  width: 100%;
  word-break: break-all;
}
</style>
